.fc {
  --fc-small-font-size: .85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: rgba(208, 208, 208, .3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: var(--cui-border-color, #{$border-color});
  --fc-button-text-color: #fff;
  --fc-button-bg-color: #2c3e50;
  --fc-button-border-color: #2c3e50;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;
  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, .25);
  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;
  --fc-non-business-color: rgba(215, 215, 215, .3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: .3;
  --fc-highlight-color: rgba(188, 232, 241, .3);
  --fc-today-bg-color: rgba(255, 220, 40, .15);
  --fc-now-indicator-color: var(--cui-danger);
}

// Calendar UI
*,
:before,
:after {
	box-sizing: border-box;
	border-width: 0;
	border-style: solid;
	border-color: #e5e7eb
}

:before,
:after {
	--tw-content: ""
}

// html {
// 	line-height: 1.5;
// 	-webkit-text-size-adjust: 100%;
// 	-moz-tab-size: 4;
// 	tab-size: 4;
// 	font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
// 	font-feature-settings: normal;
// 	font-variation-settings: normal
// }

// body {
// 	margin: 0;
// 	line-height: inherit
// }

hr {
	height: 0;
	color: inherit;
	border-top-width: 1px
}

abbr:where([title]) {
	text-decoration: underline dotted
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: inherit
}

a {
	color: inherit;
	text-decoration: inherit
}

b,
strong {
	font-weight: bolder
}

code,
kbd,
samp,
pre {
	font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
	font-size: 1em
}

small {
	font-size: 80%
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline
}

sub {
	bottom: -.25em
}

sup {
	top: -.5em
}

table {
	text-indent: 0;
	border-color: inherit;
	border-collapse: collapse
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
	margin: 0;
	padding: 0
}

button,
select {
	text-transform: none
}

// button,
// [type=button],
// [type=reset],
// [type=submit] {
// 	-webkit-appearance: button;
// 	background-color: transparent;
// 	background-image: none
// }

:-moz-focusring {
	outline: auto
}

:-moz-ui-invalid {
	box-shadow: none
}

progress {
	vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
	height: auto
}

[type=search] {
	-webkit-appearance: textfield;
	outline-offset: -2px
}

::-webkit-search-decoration {
	-webkit-appearance: none
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit
}

summary {
	display: list-item
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
	margin: 0
}

fieldset {
	margin: 0;
	padding: 0
}

legend {
	padding: 0
}

ol,
ul,
menu {
	list-style: none;
	margin: 0;
	padding: 0
}

textarea {
	resize: vertical
}

input::placeholder,
textarea::placeholder {
	opacity: 1;
	color: #9ca3af
}

button,
[role=button] {
	cursor: pointer
}

:disabled {
	cursor: default
}

// img,
// svg,
// video,
// canvas,
// audio,
// iframe,
// embed,
// object {
// 	display: block;
// 	vertical-align: middle
// }

// img,
// video {
// 	max-width: 100%;
// 	height: auto
// }

[hidden] {
	display: none
}

*,
:before,
:after {
	--tw-border-spacing-x: 0;
	--tw-border-spacing-y: 0;
	--tw-translate-x: 0;
	--tw-translate-y: 0;
	--tw-rotate: 0;
	--tw-skew-x: 0;
	--tw-skew-y: 0;
	--tw-scale-x: 1;
	--tw-scale-y: 1;
	--tw-pan-x: ;
	--tw-pan-y: ;
	--tw-pinch-zoom: ;
	--tw-scroll-snap-strictness: proximity;
	--tw-ordinal: ;
	--tw-slashed-zero: ;
	--tw-numeric-figure: ;
	--tw-numeric-spacing: ;
	--tw-numeric-fraction: ;
	--tw-ring-inset: ;
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: #fff;
	--tw-ring-color: rgb(59 130 246 / .5);
	--tw-ring-offset-shadow: 0 0 #0000;
	--tw-ring-shadow: 0 0 #0000;
	--tw-shadow: 0 0 #0000;
	--tw-shadow-colored: 0 0 #0000;
	--tw-blur: ;
	--tw-brightness: ;
	--tw-contrast: ;
	--tw-grayscale: ;
	--tw-hue-rotate: ;
	--tw-invert: ;
	--tw-saturate: ;
	--tw-sepia: ;
	--tw-drop-shadow: ;
	--tw-backdrop-blur: ;
	--tw-backdrop-brightness: ;
	--tw-backdrop-contrast: ;
	--tw-backdrop-grayscale: ;
	--tw-backdrop-hue-rotate: ;
	--tw-backdrop-invert: ;
	--tw-backdrop-opacity: ;
	--tw-backdrop-saturate: ;
	--tw-backdrop-sepia:
}

::backdrop {
	--tw-border-spacing-x: 0;
	--tw-border-spacing-y: 0;
	--tw-translate-x: 0;
	--tw-translate-y: 0;
	--tw-rotate: 0;
	--tw-skew-x: 0;
	--tw-skew-y: 0;
	--tw-scale-x: 1;
	--tw-scale-y: 1;
	--tw-pan-x: ;
	--tw-pan-y: ;
	--tw-pinch-zoom: ;
	--tw-scroll-snap-strictness: proximity;
	--tw-ordinal: ;
	--tw-slashed-zero: ;
	--tw-numeric-figure: ;
	--tw-numeric-spacing: ;
	--tw-numeric-fraction: ;
	--tw-ring-inset: ;
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: #fff;
	--tw-ring-color: rgb(59 130 246 / .5);
	--tw-ring-offset-shadow: 0 0 #0000;
	--tw-ring-shadow: 0 0 #0000;
	--tw-shadow: 0 0 #0000;
	--tw-shadow-colored: 0 0 #0000;
	--tw-blur: ;
	--tw-brightness: ;
	--tw-contrast: ;
	--tw-grayscale: ;
	--tw-hue-rotate: ;
	--tw-invert: ;
	--tw-saturate: ;
	--tw-sepia: ;
	--tw-drop-shadow: ;
	--tw-backdrop-blur: ;
	--tw-backdrop-brightness: ;
	--tw-backdrop-contrast: ;
	--tw-backdrop-grayscale: ;
	--tw-backdrop-hue-rotate: ;
	--tw-backdrop-invert: ;
	--tw-backdrop-opacity: ;
	--tw-backdrop-saturate: ;
	--tw-backdrop-sepia:
}

.fixed {
	position: fixed
}

.absolute {
	position: absolute
}

.relative {
	position: relative
}

.sticky {
	position: sticky
}

.inset-0 {
	inset: 0px
}

.left-0 {
	left: 0px
}

.right-\[2px\] {
	right: 2px
}

.top-0 {
	top: 0px
}

.top-\[2px\] {
	top: 2px
}

.z-50 {
	z-index: 50
}

.z-\[10000\] {
	z-index: 999
}

.block {
	display: block
}

.inline {
	display: inline
}

.flex {
	display: flex
}

.table {
	display: table
}

.h-12 {
	height: 3rem
}

.h-14 {
	height: 3.5rem
}

.h-\[5px\] {
	height: 5px
}

.h-\[calc\(100\%\)\] {
	height: 100%
}

.h-\[calc\(100\%\+4px\)\] {
	height: calc(100% + 4px)
}

.h-full {
	height: 100%
}

.w-12 {
	width: 3rem
}

.w-\[5px\] {
	width: 5px
}

// .w-full {
// 	width: 100%
// }

.min-w-full {
	min-width: 100%
}

.border-separate {
	border-collapse: separate
}

.border-spacing-\[0_4px\] {
	--tw-border-spacing-x: 0 4px;
	--tw-border-spacing-y: 0 4px;
	border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y)
}

@keyframes pulse {
	50% {
		opacity: .5
	}
}

.animate-pulse {
	animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite
}

@keyframes spin {
	to {
		transform: rotate(360deg)
	}
}

.animate-spin {
	animation: spin 1s linear infinite
}

.items-center {
	align-items: center
}

.justify-center {
	justify-content: center
}

.gap-1 {
	gap: .25rem
}

.gap-\[4\%\] {
	gap: 4%
}

.overflow-x-auto {
	overflow-x: auto
}

.rounded-full {
	border-radius: 9999px
}

.border {
	border-width: 1px
}

.border-b-\[0\.1px\] {
	border-bottom-width: .1px
}

.border-b-\[1px\] {
	border-bottom-width: 1px
}

.border-l-\[0\.1px\] {
	border-left-width: .1px
}

.border-gray-200 {
	--tw-border-opacity: 1;
	border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.border-white {
	--tw-border-opacity: 1;
	border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.bg-gray-200 {
	--tw-bg-opacity: 1;
	background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

.bg-gray-300 {
	--tw-bg-opacity: 1;
	background-color: rgb(209 213 219 / var(--tw-bg-opacity))
}

.bg-white {
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-yellow-100 {
	--tw-bg-opacity: 1;
	background-color: rgb(254 249 195 / var(--tw-bg-opacity))
}

.bg-opacity-75 {
	--tw-bg-opacity: .75
}

.bg-\[repeating-linear-gradient\(_135deg\,transparent_5px\,rgb\(189\,189\,189\)_10px\,rgba\(208\,204\,204\,1\)_12px_\)\] {
	background-image: repeating-linear-gradient(135deg, transparent 5px, rgb(189, 189, 189) 10px, rgba(208, 204, 204, 1) 12px)
}

.bg-\[auto_auto\] {
	background-size: auto auto
}

.px-2 {
	padding-left: .5rem;
	padding-right: .5rem
}

.py-1 {
	padding-top: .25rem;
	padding-bottom: .25rem
}

.py-2 {
	padding-top: .5rem;
	padding-bottom: .5rem
}

.pb-1 {
	padding-bottom: .25rem
}

.pl-\[40\%\] {
	padding-left: 40%
}

.pr-\[40\%\] {
	padding-right: 40%
}

.text-center {
	text-align: center
}

.text-2xl {
	font-size: 1.5rem;
	line-height: 2rem
}

.text-4xl {
	font-size: 2.25rem;
	line-height: 2.5rem
}

.text-\[15px\] {
	font-size: 15px
}

.text-sm {
	font-size: .875rem;
	line-height: 1.25rem
}

.text-xl {
	font-size: 1.25rem;
	line-height: 1.75rem
}

.font-\[500\] {
	font-weight: 500
}

.font-\[600\] {
	font-weight: 600
}

.font-\[700\] {
	font-weight: 700
}

.font-normal {
	font-weight: 400
}

.font-semibold {
	font-weight: 600
}

.text-\[\#f39200\] {
	--tw-text-opacity: 1;
	color: rgb(243 146 0 / var(--tw-text-opacity))
}

.text-black {
	--tw-text-opacity: 1;
	color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-gray-500 {
	--tw-text-opacity: 1;
	color: rgb(107 114 128 / var(--tw-text-opacity))
}

.text-white {
	--tw-text-opacity: 1;
	color: rgb(255 255 255 / var(--tw-text-opacity))
}

.opacity-25 {
	opacity: .25
}

.opacity-75 {
	opacity: .75
}

.blur {
	--tw-blur: blur(8px);
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition-\[background\] {
	transition-property: background;
	transition-timing-function: cubic-bezier(.4, 0, .2, 1);
	transition-duration: .15s
}

.transition-all {
	transition-property: all;
	transition-timing-function: cubic-bezier(.4, 0, .2, 1);
	transition-duration: .15s
}

.duration-150 {
	transition-duration: .15s
}

.duration-500 {
	transition-duration: .5s
}

.ease-in {
	transition-timing-function: cubic-bezier(.4, 0, 1, 1)
}

::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background-color: #f5f5f5
}

::-webkit-scrollbar-thumb {
	background-color: #ccc
}

::-webkit-scrollbar-corner {
	background-color: #f5f5f5
}

::-moz-scrollbar-track {
	background-color: #f5f5f5
}

::-moz-scrollbar-thumb {
	background-color: #ccc
}

::-moz-scrollbar-corner {
	background-color: #f5f5f5
}

*::-ms-scrollbar {
	width: 4px;
	height: 4px;
	background-color: #f5f5f5
}

*::-ms-scrollbar-thumb {
	background-color: #ccc
}

.year-switcher {
	position: relative
}

.year-switcher .left-button {
	position: fixed;
	height: 50px;
	width: 60px;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: 800;
	font-size: large;
	border-top-right-radius: 1px;
	border-bottom-right-radius: 1px;
	left: 0
}

.year-switcher .right-button {
	position: fixed;
	height: 50px;
	width: 60px;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: 800;
	font-size: large;
	border-top-left-radius: 1px;
	border-bottom-left-radius: 1px;
	right: 0
}

* {
	box-sizing: border-box
}

tr>.month-name-cell:hover>.week-day-cell {
	background-color: #0ff
}

.after\:absolute:after {
	content: var(--tw-content);
	position: absolute
}

.after\:left-0:after {
	content: var(--tw-content);
	left: 0px
}

.after\:top-0:after {
	content: var(--tw-content);
	top: 0px
}

.after\:h-full:after {
	content: var(--tw-content);
	height: 100%
}

.after\:w-full:after {
	content: var(--tw-content);
	width: 100%
}

.after\:bg-white:after {
	content: var(--tw-content);
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.after\:opacity-30:after {
	content: var(--tw-content);
	opacity: .3
}

.hover\:cursor-pointer:hover {
	cursor: pointer
}

.hover\:text-gray-800:hover {
	--tw-text-opacity: 1;
	color: rgb(31 41 55 / var(--tw-text-opacity))
}

.tippy-box[data-animation=fade][data-state=hidden] {
	opacity: 0
}

[data-tippy-root] {
	max-width: calc(100vw - 10px)
}

.tippy-box {
	position: relative;
	background-color: #333;
	color: #fff;
	border-radius: 4px;
	font-size: 14px;
	line-height: 1.4;
	white-space: normal;
	outline: 0;
	transition-property: transform, visibility, opacity
}

.tippy-box[data-placement^=top]>.tippy-arrow {
	bottom: 0
}

.tippy-box[data-placement^=top]>.tippy-arrow:before {
	bottom: -7px;
	left: 0;
	border-width: 8px 8px 0;
	border-top-color: initial;
	transform-origin: center top
}

.tippy-box[data-placement^=bottom]>.tippy-arrow {
	top: 0
}

.tippy-box[data-placement^=bottom]>.tippy-arrow:before {
	top: -7px;
	left: 0;
	border-width: 0 8px 8px;
	border-bottom-color: initial;
	transform-origin: center bottom
}

.tippy-box[data-placement^=left]>.tippy-arrow {
	right: 0
}

.tippy-box[data-placement^=left]>.tippy-arrow:before {
	border-width: 8px 0 8px 8px;
	border-left-color: initial;
	right: -7px;
	transform-origin: center left
}

.tippy-box[data-placement^=right]>.tippy-arrow {
	left: 0
}

.tippy-box[data-placement^=right]>.tippy-arrow:before {
	left: -7px;
	border-width: 8px 8px 8px 0;
	border-right-color: initial;
	transform-origin: center right
}

.tippy-box[data-inertia][data-state=visible] {
	transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11)
}

.tippy-arrow {
	width: 16px;
	height: 16px;
	color: #333
}

.tippy-arrow:before {
	content: "";
	position: absolute;
	border-color: transparent;
	border-style: solid
}

.tippy-content {
	position: relative;
	padding: 5px 9px;
	z-index: 1
}

.tippy-box[data-theme~=light] {
	color: #26323d;
	box-shadow: 0 0 20px 4px #9aa1b126, 0 4px 80px -8px #24282f40, 0 4px 4px -2px #5b5e6926;
	background-color: #fff
}

.tippy-box[data-theme~=light][data-placement^=top]>.tippy-arrow:before {
	border-top-color: #fff
}

.tippy-box[data-theme~=light][data-placement^=bottom]>.tippy-arrow:before {
	border-bottom-color: #fff
}

.tippy-box[data-theme~=light][data-placement^=left]>.tippy-arrow:before {
	border-left-color: #fff
}

.tippy-box[data-theme~=light][data-placement^=right]>.tippy-arrow:before {
	border-right-color: #fff
}

.tippy-box[data-theme~=light]>.tippy-backdrop {
	background-color: #fff
}

.tippy-box[data-theme~=light]>.tippy-svg-arrow {
	fill: #fff
}

.tippy-box[data-theme~=light-border] {
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 8, 16, .15);
	color: #333;
	box-shadow: 0 4px 14px -2px #00081014
}

.tippy-box[data-theme~=light-border]>.tippy-backdrop {
	background-color: #fff
}

.tippy-box[data-theme~=light-border]>.tippy-arrow:after,
.tippy-box[data-theme~=light-border]>.tippy-svg-arrow:after {
	content: "";
	position: absolute;
	z-index: -1
}

.tippy-box[data-theme~=light-border]>.tippy-arrow:after {
	border-color: transparent;
	border-style: solid
}

.tippy-box[data-theme~=light-border][data-placement^=top]>.tippy-arrow:before {
	border-top-color: #fff
}

.tippy-box[data-theme~=light-border][data-placement^=top]>.tippy-arrow:after {
	border-top-color: #00081033;
	border-width: 7px 7px 0;
	top: 17px;
	left: 1px
}

.tippy-box[data-theme~=light-border][data-placement^=top]>.tippy-svg-arrow>svg {
	top: 16px
}

.tippy-box[data-theme~=light-border][data-placement^=top]>.tippy-svg-arrow:after {
	top: 17px
}

.tippy-box[data-theme~=light-border][data-placement^=bottom]>.tippy-arrow:before {
	border-bottom-color: #fff;
	bottom: 16px
}

.tippy-box[data-theme~=light-border][data-placement^=bottom]>.tippy-arrow:after {
	border-bottom-color: #00081033;
	border-width: 0 7px 7px;
	bottom: 17px;
	left: 1px
}

.tippy-box[data-theme~=light-border][data-placement^=bottom]>.tippy-svg-arrow>svg {
	bottom: 16px
}

.tippy-box[data-theme~=light-border][data-placement^=bottom]>.tippy-svg-arrow:after {
	bottom: 17px
}

.tippy-box[data-theme~=light-border][data-placement^=left]>.tippy-arrow:before {
	border-left-color: #fff
}

.tippy-box[data-theme~=light-border][data-placement^=left]>.tippy-arrow:after {
	border-left-color: #00081033;
	border-width: 7px 0 7px 7px;
	left: 17px;
	top: 1px
}

.tippy-box[data-theme~=light-border][data-placement^=left]>.tippy-svg-arrow>svg {
	left: 11px
}

.tippy-box[data-theme~=light-border][data-placement^=left]>.tippy-svg-arrow:after {
	left: 12px
}

.tippy-box[data-theme~=light-border][data-placement^=right]>.tippy-arrow:before {
	border-right-color: #fff;
	right: 16px
}

.tippy-box[data-theme~=light-border][data-placement^=right]>.tippy-arrow:after {
	border-width: 7px 7px 7px 0;
	right: 17px;
	top: 1px;
	border-right-color: #00081033
}

.tippy-box[data-theme~=light-border][data-placement^=right]>.tippy-svg-arrow>svg {
	right: 11px
}

.tippy-box[data-theme~=light-border][data-placement^=right]>.tippy-svg-arrow:after {
	right: 12px
}

.tippy-box[data-theme~=light-border]>.tippy-svg-arrow {
	fill: #fff
}

.tippy-box[data-theme~=light-border]>.tippy-svg-arrow:after {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA2czEuNzk2LS4wMTMgNC42Ny0zLjYxNUM1Ljg1MS45IDYuOTMuMDA2IDggMGMxLjA3LS4wMDYgMi4xNDguODg3IDMuMzQzIDIuMzg1QzE0LjIzMyA2LjAwNSAxNiA2IDE2IDZIMHoiIGZpbGw9InJnYmEoMCwgOCwgMTYsIDAuMikiLz48L3N2Zz4=);
	background-size: 16px 6px;
	width: 16px;
	height: 6px
}

.tippy-box[data-theme~=material] {
	background-color: #505355;
	font-weight: 600
}

.tippy-box[data-theme~=material][data-placement^=top]>.tippy-arrow:before {
	border-top-color: #505355
}

.tippy-box[data-theme~=material][data-placement^=bottom]>.tippy-arrow:before {
	border-bottom-color: #505355
}

.tippy-box[data-theme~=material][data-placement^=left]>.tippy-arrow:before {
	border-left-color: #505355
}

.tippy-box[data-theme~=material][data-placement^=right]>.tippy-arrow:before {
	border-right-color: #505355
}

.tippy-box[data-theme~=material]>.tippy-backdrop {
	background-color: #505355
}

.tippy-box[data-theme~=material]>.tippy-svg-arrow {
	fill: #505355
}

.tippy-box[data-theme~=translucent] {
	background-color: #000000b3
}

.tippy-box[data-theme~=translucent]>.tippy-arrow {
	width: 14px;
	height: 14px
}

.tippy-box[data-theme~=translucent][data-placement^=top]>.tippy-arrow:before {
	border-width: 7px 7px 0;
	border-top-color: #000000b3
}

.tippy-box[data-theme~=translucent][data-placement^=bottom]>.tippy-arrow:before {
	border-width: 0 7px 7px;
	border-bottom-color: #000000b3
}

.tippy-box[data-theme~=translucent][data-placement^=left]>.tippy-arrow:before {
	border-width: 7px 0 7px 7px;
	border-left-color: #000000b3
}

.tippy-box[data-theme~=translucent][data-placement^=right]>.tippy-arrow:before {
	border-width: 7px 7px 7px 0;
	border-right-color: #000000b3
}

.tippy-box[data-theme~=translucent]>.tippy-backdrop {
	background-color: #000000b3
}

.tippy-box[data-theme~=translucent]>.tippy-svg-arrow {
	fill: #000000b3
}