// --cus-/* is a custom class for the container
:root {
  --cui-link-color: inherit;
  --cui-link-hover-color: inherit;
  --cus-scroll-bg: #555;
  --cui-header-bg: #e0e0e0;
  // --cui-body-bg: #ddd;
  --cui-body-font-size: 14px;
  // --cui-border-color: #9da5b1;
  --cui-border-color: hsl(0, 0%, 80%);
  --cui-border-radius: 0.2rem;
  --cus-modal-border-radius: 0.3rem; //1rem;
  --cui-secondary-bg: #f2f2f2;
}

.sidebar {
  --cui-sidebar-nav-link-color: rgb(255 255 255);
  --cui-sidebar-nav-link-hover-color: rgb(255 255 255);
  --cui-sidebar-nav-link-icon-color: rgb(255 255 255);
  --cui-sidebar-nav-link-hover-icon-color: rgb(255 255 255);
  --cui-sidebar-nav-link-active-bg: rgb(255, 255, 255);
  --cui-sidebar-nav-link-active-icon-color: black;
  --cui-sidebar-nav-link-active-color: black;
  --cui-sidebar-nav-group-toggle-show-color: rgb(255, 255, 255);
  --cui-sidebar-nav-link-hover-bg: rgb(156 164 176 / 25%);
  --cui-sidebar-brand-bg: rgba(255, 255, 255);
  --cui-sidebar-border-width: 1px;
  --cui-sidebar-nav-link-padding-y: 0.5rem;
  --cui-sidebar-nav-icon-width: 2.5rem;

  &.sidebar-fixed {
    z-index: 1029;
  }
}

li.nav-group.show {
  padding-top: 0.01em;
}

.offcanvas {
  --cui-offcanvas-width: 450px;
}

.index-0 {
  z-index: 0;
}

.index-12 {
  z-index: 12;
}

.index-13 {
  z-index: 13;
}

.modal {
  --cui-modal-header-padding: 0.5rem;
  // --cui-modal-padding: 0.5rem;
  --cui-modal-header-bg: var(--cui-header-bg);
  --cui-modal-border-color: var(--cui-primary);
  // --cui-modal-border-radius: var(--cus-modal-border-radius);
}

.btn-close {
  --cui-btn-close-focus-shadow: none;
}

.cus-modal {
  .modal-dialog-scrollable {
    .modal-content {
      height: 90vh;
    }
  }
}

.card {
  --cui-card-img-overlay-padding: 0.5rem;
  --cui-card-spacer-y: 0.5rem;
  --cui-card-spacer-x: 0.5rem;
  --cui-card-cap-padding-x: 0.5rem;
  --cui-card-cap-bg: var(--cui-header-bg);
  --cui-card-overdue-bg: rgb(237, 29, 36);
  --cui-card-header-bg: rgb(137, 3, 7);
}

.badge {
  --cui-badge-padding-x: 0.6em;
}

.accordion {
  --cui-accordion-btn-padding-x: 0.5rem;
  --cui-accordion-btn-padding-y: 0.5rem;
  --cui-accordion-active-bg: rgba(38, 171, 226, 0.35);
}

.btn {
  --cui-btn-padding-x: 0.7rem;
  --cui-btn-padding-y: 0.2rem;
}

.nav-tabs {
  --cui-nav-tabs-link-active-bg: var(--cui-primary);
  --cui-nav-tabs-link-active-color: var(--cui-white);
  --cui-nav-tabs-border-color: var(--cui-primary);
  --cui-nav-tabs-link-active-border-color: transparent;
  padding-bottom: 1px;
}

// .nav-tabs .nav-link:hover,
// .nav-tabs .nav-link:focus {
//   background-color: #f3f3f3;
// }

.pointer {
  cursor: pointer;
}

.break-word {
  word-wrap: break-word;
}

.react-loading-skeleton {
  z-index: 0 !important;
}

.accordion-button {
  color: black;

  &:not(.collapsed) {
    color: black;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-danger {
  --cui-btn-color: rgb(255, 255, 255);
  --cui-btn-hover-color: rgb(255, 255, 255);
  --cui-btn-active-color: rgb(255, 255, 255);
  --cui-btn-disabled-color: rgb(255, 255, 255);
}

.table {
  --cui-table-border-color: #9da5b1;
  margin-bottom: 0.2rem;
}

.table-dark {
  --cui-table-bg: var(--cui-header-bg);
  --cui-table-color: black;
  --cui-table-border-color: var(--cui-border-color);
}

.table-light {
  --cui-table-bg: var(--cui-header-bg);
  --cui-table-border-color: #9da5b1;
}

.table-primary {
  --cui-table-bg: var(--cui-primary);
  --cui-table-color: #ffffff;
}

.table-warning-double-light {
  --cui-table-color: black;
}

.form-multi-select {
  --cui-form-multi-select-font-size: inherit;
}

.form-multi-select-options {
  font-size: inherit;
}

.custom-select-overflow {
  .select__control {
    .select__value-container {
      height: 33px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
    }
  }
}

.alert {
  --cui-alert-padding-y: 0.7rem;
}

.alert-dismissible {
  .btn-close {
    padding: 0.9rem 1rem;
  }
}

.end-10 {
  right: 10%;
}

.end-08 {
  right: 0.8rem;
}

.top-n-8 {
  top: -8px;
}

.top-p-20 {
  top: 20px;
}

.table {
  & > tbody {
    vertical-align: middle;
    font-size: smaller;
    // font-size: 11px;
    white-space: nowrap;
  }

  & > thead {
    vertical-align: baseline;
    font-size: smaller;
    // font-size: 11px;
    // white-space: nowrap;

    th {
      min-width: 51px;
      &:first-child {
        min-width: auto;
      }
    }
  }
}

.fixTableHead.forcewidth {
  .table {
    & > thead {
      th {
        min-width: 76px;
        &:first-child {
          min-width: 200px;
        }
      }
    }
  }
}

#RFS-StepperContainer {
  padding: 10px;
}

button#RFS-StepButton {
  &.completed {
    background-color: var(--cui-green);
  }
  &.active {
    background-color: var(--cui-warning);
  }
}

#resource-allocation-mask,
#resource-allocation {
  .table {
    & > thead {
      th:first-child {
        min-width: 30px;
        width: 30px;
        max-width: 30px;
      }
    }
  }
}

#resource-allocation-mask {
  & + div {
    table {
      width: 410px;
    }
  }
}
div#resource-allocation-mask,
#resource-allocation-mask + div,
#resource-allocation-mask + div table {
  position: absolute;
}
div#resource-allocation-mask div {
  display: none;
}

#resource-allocation-mask {
  & + div {
    table {
      z-index: 1;
      top: 59px;
    }
  }
}

.blur-bg {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
}

.w-inherit {
  width: inherit;
}

.w-min-12 {
  min-width: 120px !important;
}

.w-min-15 {
  min-width: 150px !important;
}

.w-1 {
  width: 10px !important;
}

.w-2 {
  width: 68px;
}

.w-10 {
  width: 12%;
}

.w-115 {
  width: 115px !important;
}

.w-170p {
  width: 165px !important;
}

.w-17 {
  width: 175px !important;
}

.w-500 {
  width: 500px;
}

.h-300p {
  height: 300px;
}

.grid-center {
  display: grid;
  align-items: center;
}

.react-loading-skeleton {
  margin: 5px 0;
  line-height: 2 !important;
}

.preWrapText {
  white-space: pre-wrap;
}

.text-overflow {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-75 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75ch;
}

.dash-card-txt-overflow {
  white-space: normal;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 48px;
}

.modal-body-overflow {
  overflow: auto;
  max-height: calc(100vh - 140px);
}
.fs-modal-body-overflow {
  overflow: auto;
  max-height: calc(100vh - 84px);
}

.dash-card-h-w {
  height: 115px;
}

.dh-105 {
  height: 105px;
}

.dh-107 {
  height: 107px;
}

.dh-50 {
  height: calc(50% - 4px);
}

.rotate85 {
  transform: rotate(85deg);
}

.form-text {
  margin-top: 0;
  margin-bottom: 0.25rem;
  font-size: 0.8em;
  color: var(--cui-green);
}

.form-text-danger {
  .form-text {
    margin-top: 0;
    margin-bottom: 0.25rem;
    font-size: 0.8em;
    color: var(--cui-danger);
  }
}

/* In your CSS file */
.spaced-text {
  letter-spacing: 1px;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: rgb(var(--cui-light-rgb));
}

::-webkit-scrollbar-thumb {
  background: var(--cus-scroll-bg);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--cui-gray-500);
}

// Navigation Bar - Don't chaged
.sidebar-nav {
  .nav-link {
    margin: 5px 2px;
    border-radius: 50px;
    white-space: pre-wrap;
  }
}

.nav-link {
  .avatar {
    &:hover {
      box-shadow: 0px 0px 0px 5px #ececec;
    }
  }
}

.form-control,
.form-select {
  font-size: 14px;
  &:focus {
    border-color: #2684ff;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 0 1px #2684ff;
  }
}

.form-control {
  &:disabled {
    color: hsl(0, 0%, 60%);
  }
}

.picker-input-group {
  .form-control[readonly] {
    background-color: var(--cui-secondary-bg);
  }
}

.form-multi-select.show {
  border-color: #2684ff;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 0 1px #2684ff;
  .form-multi-select-dropdown {
    transform: translate3d(1px, 34.6667px, 1px) !important;
  }
}

.card-img-home {
  height: 2rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 50px;
}

.transform {
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05);
  }
}

.home-module-title {
  font-size: 13px;
  margin: 5px;
  text-shadow: 1px 1px 2px rgb(32, 31, 30);
}

.text-transform-capitalized {
  text-transform: lowercase;
  &:first-letter {
    text-transform: capitalize !important;
  }
}

.ticker-content {
  margin: -8px auto;
  padding-left: 1.85rem;
  .overlay {
    --gradient-color: none !important;
  }
  .ticker-title {
    width: auto;
    line-height: 35px;
    background: var(--cui-cyan);
    margin: inherit;
  }
}

.modal-header {
  background: var(--cui-modal-header-bg);
  // border-radius: var(--cus-modal-border-radius) var(--cus-modal-border-radius) 0 0;
}

.picker.show {
  .input-group {
    border-color: #2684ff;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 0 1px #2684ff;
  }
}

.fixed-background {
  background: url('/assets/images/back_image.jpg') no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.position-absolute-auto {
  position: absolute;
  top: auto;
}

.image-side {
  // width: 40%;
  background: url('/assets/images/ems-login.png') no-repeat top;
  background-size: cover;
  padding: 80px 40px;
}

.btn-link {
  text-decoration: none;
  font-style: italic;
}

.btn-bulk {
  position: absolute;
  width: auto;
  right: 9px;
  top: 16px;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  background-color: none !important;
}

button {
  &.btn {
    &.btn-transparent {
      background-color: var(--cui-btn-disabled-bg);
    }
  }
}

.year-switcher {
  display: none !important;
}

.text-bold {
  font-weight: 600;
}

.timelabel {
  background-color: #132459;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 50px;
  padding-left: 4px;
  padding-right: 4px;
}

.timewidth {
  width: 100px;
}

.entry {
  padding-left: 80px;
  padding-right: 80px;
}

//CoreUI date picker customization
.date-picker.cus-date-picker {
  .dropdown-menu.show {
    top: -8rem !important;
    height: fit-content;
  }
}

.dropdown-menu {
  --cui-dropdown-border-radius: var(--cus-modal-border-radius);
  --cui-dropdown-divider-margin-y: 0.2rem;
  &.show {
    border: 1px solid var(--cui-gray-200);
  }

  .dropdown-item {
    border-bottom: 1px solid var(--cui-dropdown-divider-bg);
    cursor: pointer;
  }

  li:first-child {
    .dropdown-header {
      border-radius: var(--cus-modal-border-radius) var(--cus-modal-border-radius) 0 0;
    }
  }

  li:last-child {
    .dropdown-header {
      border-radius: 0 0 var(--cus-modal-border-radius) var(--cus-modal-border-radius);
    }
    .dropdown-item:first-child {
      border-radius: var(--cus-modal-border-radius) var(--cus-modal-border-radius) 0 0;
    }
    .dropdown-item:last-child {
      border-radius: 0 0 var(--cus-modal-border-radius) var(--cus-modal-border-radius);
      border-bottom: none;
    }
  }
}

.calendar {
  --cui-calendar-table-margin: 0.1rem;
}

.calendar-cell {
  font-size: small;
}

//calender view *don't make any changes here
.resource-calendar {
  .relative {
    button {
      display: none;
    }
  }
}

#calendar {
  max-width: 1200px;
  margin: 40px auto;
}

.hcalender {
  .fc table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 12px;
  }
  .fc .fc-toolbar-title {
    font-size: 15px;
    margin: 0;
  }
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 2px;
  }
  .fc .fc-button {
    padding: 5px;
    font-size: 13px;
  }
  .fc .fc-daygrid-day-events {
    position: relative;
    min-height: 0px !important;
  }
  .fc .fc-daygrid-body {
    position: relative;
    z-index: 1;
    font-size: 11px !important;
  }
}

.homecalender {
  &.hcalender {
    .fc .fc-daygrid-day-events {
      margin-top: -12px;
    }
  }
}

.calendar-loading-mask {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  width: -webkit-fill-available;
  height: 84%;
  z-index: 2;
}

.cus-border-se {
  border-right: 1px solid #000;
  border-left: 1px solid #000;
}

.fc-daygrid-event-harness {
  margin-top: 0px;
  width: 32px;
  text-align: center;
}

.fc-daygrid-day-events {
  display: flex;
  justify-content: center;
  max-height: 0px;
  z-index: -1;
}

//CDatepicker calendar
.calendar-nav {
  padding: inherit;
}
.calendar-cell-inner {
  height: auto;
}
.calendar-header-cell-inner {
  height: auto;
}

.form-check-input {
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
}

.required::after {
  content: '*';
  color: crimson;
}

.border-red {
  border: 1px solid var(--cui-form-invalid-border-color);
  border-radius: 5px;
}

.fixTableHead table {
  overflow-y: auto;
  height: 600px;
  display: block;
}
.fixTableHead table thead {
  position: sticky;
  top: 0;
}

.fixTableHead1 table {
  overflow-y: auto;
  height: 500px;
  display: block;
}
.fixTableHead1 table thead {
  position: sticky;
  top: 0;
}

.fixTableHead2 {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 206px;
  width: auto;
}

.fixTableHead2 table thead {
  position: sticky;
  top: 0;
}
.fixTableHead3 {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 331px;
  width: auto;
}

.fixTableHead3 table thead {
  position: sticky;
  top: 0;
}

#scheduling-table ~ div table th span {
  opacity: 1 !important;
}

#update-scheduling-table ~ div table th span {
  opacity: 1 !important;
}

.fs-6-5 {
  font-size: 15px;
}

.fs-7 {
  font-size: 14px;
}

.fs-7-5 {
  font-size: 0.772rem;
}

.fs-8 {
  font-size: 12px;
}

.fs-9 {
  font-size: 10px;
}

.p-1-5 {
  padding: 0.4rem;
}

.btn-primary {
  .badge {
    top: 0px;
  }
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  background-color: var(--cui-gray-900);
}

.non-scrollable-x {
  overflow-x: inherit;
}

.card {
  .card-overdue-bg {
    background: var(--cui-card-overdue-bg);
    padding: 3px 10px;
    border-radius: 4px;
    box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .card-header-bg {
    background: var(--cui-card-header-bg);
    position: absolute;
    width: 48%;
    padding: 4px;
    left: 0;
    right: 0;
    top: -14px;
    border-radius: 30px;
  }
  .card-border {
    border: 1px solid #e4bcbc;
  }
  .rounded-pill.text-white.fs-8.fw-semibold {
    min-width: 25px;
    --cui-btn-padding-x: 0.4rem;
  }
}

.input-group-text {
  font-size: 13px;
}

div.warning {
  background: yellow;
  display: inline-block;
  padding: 4px 16px;
  margin-top: -4px;
  margin-bottom: 2px;
}

.rmdp-calendar-container-mobile {
  position: static;
  left: 0px;
  top: 0px;
  will-change: transform;
  visibility: visible;
  z-index: 100;
  background-color: white;
}

.ts-cell {
  margin-bottom: -0.3rem;
}

.ra-cell {
  border-bottom: 1px solid #9da5b1;
  margin-top: -0.3rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding: 0 0.5rem;
}

.jrPage * {
  font-size: 14px !important;
}

.table-responsive {
  margin-bottom: 10px;
}

.custom-form-input {
  input.form-control {
    font-weight: 600;
  }

  .select__control {
    font-weight: 600;
  }
}

.dashboard-table {
  th {
    border-bottom-width: 0px;
  }
}

@media (min-width: 768px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .image-side {
    width: 100%;
    height: 300px;
    padding: 60px;
  }
  .col-lg-20 {
    flex: 0 0 auto;
    width: 50%;
  }

  .card {
    .card-link {
      .card-img-overlay {
        .card-title {
          font-size: 0.9rem;
        }
      }
    }
  }

  .hcalender {
    .fc .fc-daygrid-day-events {
      position: relative;
      min-height: 25px !important;
    }
  }
}

@media (max-width: 992px) {
  .dash-card-h-w {
    width: -webkit-fill-available;
  }
}

@media (min-width: 992px) {
  .col-lg-20 {
    flex: 0 0 auto;
    width: 20%;
  }
}
